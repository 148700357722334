/* Container of Modal */
.container {
  position: absolute; /* Stay in place */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  overflow: auto; /* Enable scroll if needed */
}

/* Content of Modal */
.modalcontent {
  position: fixed;
  background-color: #fefefe;
  padding: 1%;
  border: 5px solid #000;
  border-radius: 50px;
  height: fit-content;
  width: 40%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* Header of Modal */
.modalheader {
  margin-top: 5%;
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
}

/* Container of Buttons */
.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
}

/* Right and left buttons */
.containerRight, .containerLeft{
  flex-direction: column;
  width: 100%;
}

/* Buttons */
.button {
  background-color: var(--primary-bg-color);
  width: 70%;
  margin-bottom: 8%;
  padding: 3%;
  border-radius: 30px;
  font-size: 1.3rem;
  color: var(--game-blue-btn-text-color);
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button:hover {
  background-color: var(--game-btn-hover-color);
}

.button:disabled {
  background-color: var(--game-btn-locked-color);
}

/* Close icon */
.close {
  position: absolute;
  top: 3%;
  right: 3%;
  cursor: pointer;
}

@media screen and (max-width: 1500px) {
  .close {
    position: absolute;
    top: 3%;
    right: 3%;
    cursor: pointer;
  }

}
