.layoutWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.layoutContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.blueHeader {
  height: 10%;
  width: 100%;
  text-align: center;
  background-color: var(--program-border-color);
  border-color: var(--program-border-color);
  display: flex;
  align-items: center;
}

.programHeading {
  color: white;
  font-family: var(--title-text-font);
  font-size: 3rem;
  margin-right: auto;
  margin-left: auto;
  font-weight: 500;
}

.navigationContainer {
  width: clamp(140px, 24%, 400px);
  height: calc(90% - 60px);
  position: relative;
}

.navigationContainer::after {
  content: "";
  position: absolute;
  right: 0;
  top: 8px;
  bottom: 16px;
  width: 3px;
  background-color: black;
}

.subpageContainer {
  padding: 2vh 8px;
  flex: 1;
  height: calc(90% - 140px);
  overflow-y: scroll;
}
