.container{
    margin-top: 2%;
    padding-bottom: 15vh;
}

.places{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.map{
    margin-top: 2%;
}