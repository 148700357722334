/* Container of Modal */
.modal {
  position: absolute; /* Stay in place */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
}

/* Content of Modal */
.modalcontent {
  position: fixed;
  background-color: #fefefe;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border: 5px solid #000;
  border-radius: 50px;
  height: 50%;
  width: 70%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* Header of Modal */
.modalheader {
  margin-top: 3%;
  font-size: 3rem;
  font-weight: 600;
}

/* Container of Buttons */
.buttonContainer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 4%;
}

/* Button */
.button {
  background-color: var(--primary-bg-color);
  width: fit-content;
  display: flex;
  justify-content: center;
  margin-bottom: 2%;
  padding: 1.5%;
  border-radius: 40px;
  font-size: 1.2vw;
  background-position: center;
  background-size: cover;
  color: var(--game-blue-btn-text-color);
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.button:hover {
  background-color: var(--game-btn-hover-color);
}

/* Text */
.intro {
  font-size: 1.6vw;
  margin-top: 2%;
}
