.logsWrapper {
  background-color: #f8f9fc;
  padding: 0 10px 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.logsHeaderContainer {
  text-align: start;
  padding-left: 10px;
}
.logsHeader {
  color: #5a5c68;
}

.logsCardContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 20px;
}
.logsHeaderContainer {
  text-align: start;
  padding-left: 10px;
}
.logseHeader {
  color: #5a5c68;
}

.logsCard {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 0.25rem;
  align-self: stretch;
  width: 100%;
  max-width: 800px;
  max-height: 300px;
  padding: 10px;
  overflow: scroll;
}
.logsCardHeaderContainer {
  background-color: #f8f9fc;
  text-align: start;
  padding-left: 10px;
}

.logsCardHeader {
  color: var(--program-border-color);
  margin: 8px 0 0 0;
}

.logsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.logsTableText {
  color: #858795;
}

.logsTableContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 4px;
  width: 80%;
  margin: auto;
}

.logsMessageContainer {
  display: flex;
  font-size: 0.9rem;
  background-color: #858795;
  border-radius: 0.5rem;
  gap: 4px;
  padding: 10px;
}
.logMessage {
  word-break: break-word;
}
