/* Container of Modal */
.modal {
  position: absolute; /* Stay in place */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
}

/* Modal Content */
.modalcontent {
  position: fixed;
  background-color: #fefefe;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  border: 4px solid #000;
  border-radius: 50px;
  width: 70%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  animation-duration: 2s;
  animation-name: anime;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-delay: 0s;
  animation-direction: alternate;
}

/* Content of Text */
.content {
  display: block;
  background-color: #00000000;
  margin: auto;
  animation-name: showContent;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-delay: 0s;

  visibility: hidden;
}

/* Header of Modal */
.modalheader {
  color: black;
  justify-content: center;
}

.center {
  display: flex;
  justify-content: center;
}

/* Line */
.line {
  border-bottom: 1px solid #000;
  width: 30%;
  margin-bottom: 0.5%;
}

/* Canvas animation */
.animation {
  margin-top: 0.5%;
  justify-content: center;
}

/* Description */
.description {
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
}

/* Container of Question */
.questionContainer {
  display: flex;
  justify-content: center;
}

/* Question */
.question {
  margin-top: 0.5%;
  margin-bottom: 0.5%;
}

/* Footer */
.modalfooter {
  padding-left: 5%;
  padding-right: 5%;
}

/* Container of Buttons */
.buttonContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 1.5%;
  margin-top: 1.5%;
}

/* Button text */
.option {
  font-size: 1rem;
}

.optionText {
  word-break: break-all;
}

/* Button */
.button {
  background-color: var(--primary-bg-color);
  border-radius: 50px;
  font-size: 1.3rem;
  color: var(--game-blue-btn-text-color);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 70px;
  color: white;
  text-align: center;
  padding: 0px 8px;
}

.buttonDisabled {
  background-color: var(--primary-bg-color);
  border-radius: 50px;
  font-size: 1.3rem;
  color: var(--game-blue-btn-text-color);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 70px;
  color: white;
  text-align: center;
  opacity: 0.5;
  cursor: not-allowed;
}

/* Buttons on hover event */
.button:hover,
.buttonDisabled:hover {
  transform: scale(103%);
  transition: all 0.4s ease;
  cursor: pointer;
}

/* Tooltip text */
.button .tooltiptext,
.buttonDisabled .tooltiptext {
  visibility: hidden;
  justify-content: center;
  width: 200px;
  background-color: #fff;
  color: #000;
  text-align: center;
  padding: 5px 5px;
  border-radius: 10px;
  border: 3px solid rgb(226, 30, 121);

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 1s;
}

/* Tooltip arrow */
.button .tooltiptext::after,
.buttonDisabled .tooltiptext::after {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.button:hover .tooltiptext,
.buttonDisabled:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Close button */
.closeButton {
  position: absolute;
  top: 3%;
  right: 2%;
  background-color: var(--primary-bg-color);
  color: white;
  padding: 5px 15px;
  border-radius: 30px;
  cursor: pointer;
}

/* Modal Animation */
@keyframes anime {
  0% {
    width: 60px;
    height: 40px;
  }
  50% {
    width: 60px;
    height: 47%;
  }
  100% {
    width: 70%;
  }
}

/* Show content animation */
@keyframes showContent {
  0% {
    visibility: hidden;
  }
  65% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

@media screen and (max-width: 1700px) {
  /* Modal Content */
  .modalcontent {
    position: fixed;
    background-color: #fefefe;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    border: 4px solid #000;
    border-radius: 50px;
    width: 70%;
    max-height: 80%;
    overflow-y: scroll;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    animation-duration: 2s;
    animation-name: anime;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    animation-delay: 0s;
    animation-direction: alternate;
  }

  .button {
    align-items: center;
    justify-content: center;
    background-color: rgb(226, 30, 121);
    width: fit-content;
    max-width: 40%;

    border-radius: 10px;
    color: white;
    text-align: center;
  }

  @keyframes anime {
    0% {
      width: 60px;
      height: 40px;
    }
    50% {
      width: 60px;
      height: 70%;
    }
    100% {
      width: 70%;
    }
  }
}
