.container {
  justify-content: center;
  padding-top: 1%;
  width: 100%;
  padding-bottom: 11vh;
  overflow-y: auto;
}

.email {
  display: flex;
  margin-bottom: 1%;
  width: 100%;
  background-color: var(--email-btn-color);
  cursor: pointer;
  align-items: center;
}

.email:hover {
  display: flex;
  margin-bottom: 1%;
  width: 100%;
  background-color: var(--email-btn-hover-color);
  cursor: pointer;
  align-items: center;
}

.left {
  width: 35%;
  text-align: start;
  padding-left: 1%;
  padding-top: 1%;
  font-weight: 500;
}

.subject {
  font-size: 2.3rem;
}
