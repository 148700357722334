.statisticsWrapper {
  background-color: #f8f9fc;
  padding: 0 10px 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.statisticsHeaderContainer {
  text-align: start;
  padding-left: 10px;
}
.statisticsHeader {
  color: #5a5c68;
}

.statisticsItemContainer {
  display: flex;

  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 12px;
  padding: 10px;
}

.ressourceChartContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 20px;
}
.ressourceHeaderContainer {
  text-align: start;
  padding-left: 10px;
}
.ressourceHeader {
  color: #5a5c68;
}

.upgradeHeaderContainer {
  text-align: start;
  padding-left: 10px;
}

.upgradeHeader {
  color: var(--program-border-color);
  margin: 12px 0 0 0;
}

.chartCard {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 0.25rem;
  align-self: stretch;
  width: clamp(200px, 80%, 600px);
}
.chartCardHeaderContainer {
  background-color: #f8f9fc;
  text-align: start;
  padding-left: 10px;
}

.chartCardHeader {
  color: var(--program-border-color);
  margin: 8px 0 0 0;
}

.chartContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.chartLine {
  position: relative;
  height: 16rem !important;
}

.logsTableText {
  color: #858795;
}
