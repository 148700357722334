.container{
    background-color: white;
    width: 75vw;
    height: 4.5rem;
    border-bottom: 1px solid rgba(182, 182, 182, 0.466);
    position: sticky;
}

.menu{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 1.5rem;
    padding-left: 300px;
    padding-right: 300px
}

.right{
    position: absolute;
    right: 2%;
    font-weight: 500;
    color: gray;
}

.left{
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bolder;
}

.link{
    text-decoration: none;
    color: black;
}

.item{
    float: left;
    margin-right: 30px;
    text-decoration: none;
}

@media screen and (max-width: 1200px) {
    .right{
        display: none;
    }
    .left{
        align-items: center;
    }
}