.container {
  text-align: start;
  padding-bottom: 10vh;
}

.heading {
  text-align: start;
  font-size: 2.2rem;
  font-weight: 700;
  margin: 15px 0;
}

.subheader {
  font-weight: 700;
  margin-top: 2.5%;
  margin-bottom: 1%;
}

.item {
  text-decoration: none;
}

.item:visited {
  color: var(--primary-bg-color);
}

.link {
  text-decoration: none;
}

.animationContainer {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
}


