.container{
    padding-bottom: 10vh;
}

.navBar{
    font-size: 2.8rem;
    display: flex;
    justify-content: space-evenly;
    font-weight: 700;   
}

.link{
    color: #4D4D4D;
    text-decoration: none;
}

.footer{
    background-color: #FF7700;
    padding-top: 1%;
    padding-bottom: 1%;
}

.impressumLink{
    font-weight: 700;
    font-size: 2.8rem;
    color: #4D4D4D;
    text-decoration: none;
}