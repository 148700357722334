.navItem {
  width: 5vw;
  margin-left: 3%;
  margin-right: 3%;
  background-color: var(--taskbar-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.navItemDate {
  margin-left: 3%;
  margin-right: 3%;
  background-color: var(--taskbar-color);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.icon {
  width: clamp(10px, 3.5vw, 2.5rem);

  cursor: pointer;
}

.icon:hover {
  transform: scale(1.1);
}

.navItem:hover {
  transition: all 0.4s ease;
}

/* Tooltip text */
.navItem .tooltip {
  justify-content: center;
  width: 120px;
  background-color: #fff;
  color: #000;
  text-align: center;
  padding: 5px 5px;
  border-radius: 10px;
  border: 3px solid rgb(226, 30, 121);
  bottom: 9vh;
  margin-left: -100px;

  /* Position the tooltip text */
  position: absolute;
  z-index: auto;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.7s;
  visibility: hidden;
}

.checks {
  padding: 4px;
}

/* Tooltip arrow */
.navItem .tooltip::after {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.navItem:hover .tooltip {
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.taskbarWrapper {
  position: fixed;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  overflow: visible;
  background-color: var(--taskbar-color);
}
.taskbarClipped {
  position: absolute;
  top: -60px;
  background-color: var(--taskbar-color);
  right: 0;
  height: 60px;
  box-sizing: border-box;
  padding: 4px;
  z-index: 1;
}
.taskbarClipped::before {
  content: "";
  position: absolute;
  background-color: var(--taskbar-color);
  height: 60px;
  width: 50px;
  left: -49px;
  bottom: 0;
  clip-path: polygon(0 83%, 100% 0%, 100% 100%, 0% 100%);
}
.taskbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
}

.taskbar * {
  background-color: var(--taskbar-color);
}

.date {
  color: white;
  font-size: 2rem;
  font-weight: 400;
}

.ressourceContainer {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex: 2;
  height: 100%;
  gap: 1vw;
}

.budgetContainer,
.userContainer {
  display: flex;
  flex-direction: column;

  height: 100%;
  justify-content: center;
}

.modifierWrapper {
  font-size: clamp(10px, 1.5vw, 0.7rem);
  flex: 1;
  display: flex;
}

.modifier {
  color: white;
  margin: auto;
}

.timeContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 90%;
}

.progressBarContainer {
  position: relative;
  width: clamp(100px, 16vw, 200px);
  height: 50%;
  margin-top: 6px;
}

.progressBar {
  width: clamp(100px, 16vw, 200px);
  height: 100%;
  background-color: #f4d4e3;
  position: absolute;
  top: 14%;
}
.progressBarTrack {
  height: 100%;
  background-color: #d03978;
  position: absolute;
  top: 14%;
  z-index: 1;
}

.buttonContainer {
  height: 50%;

  width: clamp(100px, 16vw, 200px);
  position: relative;
  display: flex;
  flex-direction: row;
  bottom: 4px;
  gap: 12px;
  justify-content: center;
}

.buttonRunning,
.buttonFastForward {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d03978;
  width: clamp(20px, 40%, 50px);
}

.buttonRunning:hover > svg,
.buttonFastForward:hover > svg {
  scale: 0.8;
}

.budgetWrapper,
.userWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.budget,
.user {
  font-size: clamp(10px, 2.5vw, 2rem);
  color: white;
  display: inline-flex;
  align-items: center;
}

.euro,
.userIcon {
  padding-right: 5px;
  width: clamp(10px, 2vw, 1.5rem);
  color: white;
}
.fastForwardIcon,
.playPauseIcon,
.fastForwardIconSelected,
.playPauseIconSelected {
  width: clamp(10px, 1.8vw, 20px);
  color: white;
  background-color: transparent;
  transition: scale 0.2s ease;
}
.fastForwardIconSelected * path,
.playPauseIconSelected * path {
  fill: var(--taskbar-color);
}

@media screen and (min-width: 4000px) {
  .navItem {
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 2000px) {
  .navItem {
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 1500px) {
  .navItem {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 1300px) {
  .navItem {
    font-size: 1.3rem;
  }
}
