.buttonContainer {
  background-color: var(--program-border-color);
  padding: 10px;
  border-radius: 8px;
  transition: all 0.2s;
  font-size: 16px;
}

.buttonContainer:hover {
  transform: scale(1.05);
}

.button {
  margin: 0;
  text-decoration: none;
  color: white;
}

.navigationText {
  margin: 0;
}

.navigatorButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 10px;
}
