.overviewWrapper {
  background-color: #f8f9fc;
  padding: 0 10px 10px 10px;
  display: flex;
  flex-direction: column;
}

.overviewHeaderContainer {
  text-align: start;
  padding-left: 10px;
}
.overviewHeader {
  color: #5a5c68;
}

.overviewItemContainer {
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  padding: 10px;
}
