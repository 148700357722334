.heading {
  text-align: start;
  font-size: 2.2rem;
  font-weight: 700;
  margin: 15px 0;
}

.content {
  text-align: start;
}

.introPicture {
  margin: 2% 0;
}

.container {
  text-align: start;
}
