.itemWrapper {
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 40%;
  max-width: 380px;
  min-width: 260px;
  min-height: 12vh;
  border-left: 3px solid black;
  border-radius: 0.25rem;
  padding: 4px 12px;
  background-color: white;
  transition: all 0.2s ease-in-out;
}
.itemWrapper:hover {
  transform: scale(1.01);
}

.itemTextContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}

.itemText {
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 1rem;
  margin: 0;
}

.itemIconContainer svg {
  margin-left: auto;
  height: 60px;
  width: 60px;
  fill: #aaaaaa;
}

.progressBarContainer,
.valueContainer {
  position: relative;
  width: clamp(100px, 16vw, 200px);
  height: 10px;
}

.progressBarPercentContainer {
  padding: 2px;
  gap: 8px;
  display: flex;
  align-items: center;
}

.valueContainer p {
  font-size: 1rem;
  margin: 6px;
  font-weight: bolder;
}

.progressBarPercentContainer p {
  margin: 0;
  font-size: 1rem;
}

.progressBar {
  width: clamp(100px, 16vw, 200px);
  height: 100%;
  background-color: #f4d4e3;
  position: absolute;
  top: 14%;
  border-radius: 1rem;
}
.progressBarTrack {
  height: 100%;
  position: absolute;
  top: 14%;
  z-index: 1;
  transition: all 0.2s linear;
  border-radius: 1rem;
}
