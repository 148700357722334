.container {
  padding-bottom: 11vh;
}

.navBar {
  font-size: 2rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: 500;
  position: sticky;
  display: inline-flex;
  width: 100%;
  height: 7vh;
  border-bottom: 1px solid black;
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

.link {
  color: black;
  text-decoration: none;
  padding: 2%;
}

.footer {
  background-color: #ff7700;
  padding-top: 1%;
  padding-bottom: 1%;
}

.impressumLink {
  font-weight: 700;
  font-size: 2.8rem;
  color: #4d4d4d;
  text-decoration: none;
}

.content {
  text-align: justify;
  width: 70%;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
}

.cardContainer {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 40px 0;
}

.card {
  position: relative;
  min-width: 320px;
  height: 440px;
  box-shadow: 0px 1px 31px -6px rgba(0, 0, 0, 0.55);
  margin: 20px;
  transition: 0.5s;
}

.box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

.cardContent {
  padding: 20px;
  text-align: center;
}

.list {
  font-size: 1.2rem;
  text-align: start;
}

.price {
  font-weight: 500;
}
