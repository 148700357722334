.container {
  width: 100%;
  margin-bottom: 12vh;
}

.header {
  display: grid;
  grid-template-columns: 1.8fr 0.7fr;
  gap: 0px 0px;
  margin-top: 2.2%;
  font-size: 2.3rem;
  font-weight: 700;
  padding-left: 5%;
  padding-right: 5%;
}

.subject {
  grid-column-end: 1 / 2;
  text-align: start;
}

.right {
  display: flex;
  float: right;
  justify-content: space-around;
  font-size: 30px;
}

.spamButton {
  background-color: var(--primary-bg-color);
  width: 60%;
  height: 90%;
  display: flex;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  font-size: 1.2vw;
  background-position: center;
  background-size: cover;
  color: var(--game-blue-btn-text-color);
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.spamButton:hover {
  background-color: var(--game-btn-hover-color);
  transform: scale(103%);
  transition: all 0.4s ease;
}

.infos {
  font-size: 1.8rem;
  font-weight: 500;
  overflow: hidden;
}

.sender {
  float: left;
  text-align: start;
  padding-left: 5%;
}

.date {
  float: right;
  margin-top: 2.5%;
  padding-right: 7%;
}

.content {
  position: relative;
  text-align: start;
  padding-left: 5%;
  padding-right: 7%;
  margin-top: 3%;
}

@media screen and (max-width: 1500px) {
  .header {
    display: grid;
    grid-template-columns: 1.8fr 0.7fr;
    gap: 0px 0px;
    margin-top: 2.2%;
    font-size: 2.1rem;
    font-weight: 700;
    padding-left: 5%;
    padding-right: 5%;
  }
}
