* {
  box-sizing: border-box !important;
}
@font-face {
  font-family: grandHotel;
  src: url(/src/assets/fonts/GrandHotel-Regular.ttf);
}

@font-face {
  font-family: libSans;
  src: url(/src/assets/fonts/LiberationSans.ttf);
}
html {
  font-size: calc(10px + 0.390625vw);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*Defined Colors and Fonts*/
:root {
  /*Main Color*/
  --primary-bg-color: #ed3994;
  /*Taskbar Color*/
  --taskbar-color: #061f63;
  /*Dashboard Color*/
  --dashboard-color: #fad2e4;
  /*Border Color*/
  --program-border-color: #0084ef;

  /*Button Color*/
  --program-btn-color: #0072ce;
  --program-btn-hover-color: #0042a0;
  --program-btn-active-color: #001f62;
  --program-btn-focus-color: #0084ef;

  /* Font Colors */
  --game-pink-btn-text-color: #ffffff;
  --taskbar-text-color: #ffffff;
  --game-blue-btn-text-color: #ffffff;
  --std-text-color: #000000;

  /* Chat */
  --chat-player-message-color: #fad2e4;
  --chat-npc-message-color: #eaeaea;

  --chat-btn-color: #fad2e4;
  --chat-btn-hover-color: #f4a4c9;
  --chat-btn-active-color: #ed78ae;
  --chat-btn-focus-color: #fad2e4;

  /* E-Mail Single View */
  --email-btn-color: #cce3f5;
  --email-btn-hover-color: #9ccef5;
  --email-btn-active-color: #dceefd;
  --email-btn-focus-color: #7ea7ff;

  /*Color Pink*/
  --game-btn-color: #e21e79;
  --game-btn-hover-color: #7a1041;
  --game-btn-active-color: #ae165d;
  --game-btn-focus-color: #e21e79;
  /* Disabled Button */
  --game-btn-locked-color: #c8c8c8;

  /*Fonts*/
  --title-text-font: url("assets/fonts/GrandHotel-Regular.ttf");
}

html h1 {
  font-family: grandHotel;
  font-display: swap;
  font-size: 3.2rem;
  font-weight: 300;
  line-height: 50%;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    scroll-behavior: auto !important;
    animation-duration: 0.01s !important;
    transition-duration: 0.01s !important;
  }
}
