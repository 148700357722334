/* Container of Dashboard */
.container {
  width: 20vw;
  height: 90vh;
  background-color: var(--dashboard-color);
  position: fixed;
  right: 0;
  z-index: 1;
}

/* Header question */
.question {
  font-size: 1.8rem;
  margin-bottom: 10%;
  margin-left: 4%;
  margin-right: 4%;
}

/* Points of locked solutions */
.point {
  font-size: 2rem;
}

/* Container of Buttons */
.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Buttons */
.button {
  background-color: var(--primary-bg-color);
  width: calc(100% - 20px);
  overflow-x: scroll;
  display: flex;
  justify-content: center;
  margin-bottom: 2%;
  padding: 4%;
  border-radius: 40px;
  font-size: 1.2vw;
  background-position: center;
  background-size: cover;
  color: var(--game-blue-btn-text-color);
  border: none;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 5%;
}

/* Disabled Buttons */
.buttonDisabled {
  background-color: var(--game-btn-locked-color);
  width: 60%;
  display: flex;
  justify-content: center;
  margin-bottom: 2%;
  padding: 4%;
  border-radius: 40px;
  font-size: 1.2vw;
  background-position: center;
  background-size: cover;
  color: var(--game-blue-btn-text-color);
  border: none;
  text-decoration: none;
  margin-bottom: 5%;
}

/* Tooltip */
.button .tooltip {
  visibility: hidden;
  justify-content: center;
  width: 270px;
  background-color: #fff;
  color: #000;
  text-align: center;
  padding: 5px 5px;
  border-radius: 10px;
  border: 3px solid rgb(226, 30, 121);
  font-size: 1rem;

  /* Position the tooltip text */
  position: absolute;
  bottom: 125%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 1s;
}

/* Tooltip arrow */
.button .tooltip::after {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.button:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* Header of Dashboard */
.header {
  font-size: 3.5rem;
  text-decoration: underline;
}

.dayContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.statusContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.statusContainer h5 {
  margin: 6px;
}

.statusWrapper {
  display: flex;
  gap: 2px;
  align-items: center;
  padding: 0px 4px;
}
.statusWrapper svg {
  height: 20px;
  width: 20px;
}

.dayContainer svg {
  height: 30px;
  width: 30px;
}

.incidentContainer {
  padding: 0px 4px;
  display: flex;
  flex-direction: column;
}
