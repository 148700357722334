/* Container of Modal */
.container {
  position: absolute; /* Stay in place */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
}

/* Content of Modal */
.modalcontent {
  position: fixed;
  background-color: #fefefe;
  padding-left: 20px;
  padding-right: 20px;
  border: 5px solid #000;
  border-radius: 50px;
  width: 60%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* Header od Modal */
.modalheader {
  margin-top: 6%;
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
}

/* Heading */
.heading {
  font-size: 2.8rem;
  font-weight: 600;
  margin-bottom: 2%;
}

/* Content */
.content {
  margin-left: 3%;
  margin-right: 3%;
  font-size: 2rem;
  text-align: start;
}

/* Question of Decision */
.question {
  font-size: 2rem;
}

/* Container of Buttons */
.buttonContainer {
  width: 70%;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 2%;
}

/* Buttons */
.button {
  background-color: var(--primary-bg-color);
  width: 25%;
  display: flex;
  justify-content: center;
  margin-bottom: 2%;
  padding: 1.5%;
  border-radius: 30px;
  font-size: 1.3rem;
  background-position: center;
  background-size: cover;
  color: var(--game-blue-btn-text-color);
  border: none;
  cursor: pointer;
}

/* Buttons on hover event */
.button:hover {
  background-color: var(--game-btn-hover-color);
}

/* Disabled Buttons */
.button:disabled {
  background-color: var(--game-btn-locked-color);
}

@media screen and (min-width: 3000px) {
  .heading{
    font-size: 3.5rem;
  }
  .modalcontent{
    height: 50%;
  }
  .content {
    font-size: 3rem;
  }
  .question {
    font-size: 3rem;
  }
  .button{
    font-size: 2rem;
  }
}

@media screen and (max-width: 2300px){
  .content {
    font-size: 2.1rem;
  }
  .question{
    font-size: 2.1rem;
  }
}

@media screen and (max-width: 1800px){
  .content {
    font-size: 1.75rem;
  }

  .question{
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 1500px){
  .content {
    font-size: 1.4rem;
  }

  .question{
    font-size: 1.5rem;
  }
}