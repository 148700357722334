.center {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.messagesContainer {
  margin-top: 1%;
  width: 75vw;
  height: 53vh;
  padding-right: 1%;
  padding-left: 1%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.messageNPC {
  background-color: var(--chat-npc-message-color);
  border-radius: 30px;
  border-bottom-left-radius: 0;
  height: auto;
  width: fit-content;
  padding: 1%;
  margin-bottom: 1%;
  text-align: start;
  align-self: flex-start;
}

.messagePlayer {
  background-color: var(--chat-player-message-color);
  border-radius: 30px;
  border-bottom-right-radius: 0;
  height: auto;
  width: fit-content;
  padding: 1%;
  margin-bottom: 1%;
  text-align: end;
  align-self: flex-end;
}

.typeBox {
  background-color: var(--chat-npc-message-color);
  border-radius: 30px;
  border-bottom-left-radius: 0;
  background-repeat: no-repeat;
  height: auto;
  width: fit-content;
  padding: 1%;
  margin-bottom: 1%;
  display: block;
}

/*Type animation*/
.typeDot:nth-child(1) {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: gray;
  border-radius: 50%;
  display: inline-block;
  animation-duration: 2.5s;
  animation-name: jumping-dots;
  animation-iteration-count: infinite;
}

.typeDot:nth-child(2) {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: gray;
  border-radius: 50%;
  display: inline-block;
  animation-duration: 2.5s;
  animation-name: jumping-dots;
  animation-delay: 0.5s;
  animation-iteration-count: infinite;
}

.typeDot:nth-child(3) {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: gray;
  border-radius: 50%;
  display: inline-block;
  animation-duration: 2.5s;
  animation-name: jumping-dots;
  animation-delay: 1s;
  animation-iteration-count: infinite;
}

.messagesAndAnswerContainer {
  overflow-y: scroll;
  overflow-x: hidden;
}

/*Answer*/
.answersContainer {
  background-color: white;
  position: absolute;
  margin-left: 0.7%;
  bottom: 12vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: #000 solid 2px;
  border-radius: 30px;
  width: 75%;
  height: 15vh;
  font-size: 1.5rem;
  font-weight: 500;
  flex-direction: column;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
}

.answerPlayer {
  background-color: var(--chat-btn-color);
  width: 70%;
  height: 100px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1%;
  cursor: pointer;
  font-size: 1.3rem;
  transition: background-color 0.7s ease;
}

.answerDisabled {
  background-color: var(--game-btn-locked-color);
  width: 70%;
  height: 100px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1%;
  font-size: 1.3rem;
  transition: background-color 0.5s ease;
}

.answer:hover {
  background-color: var(--chat-btn-hover-color);
}

.answerHidden {
  display: none;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1%;
  height: fit-content;
}

.previous {
  display: flex;
  margin-right: 2%;
  cursor: pointer;
}

.next {
  display: flex;
  margin-left: 2%;
  cursor: pointer;
}

.dotContainer {
  text-align: center;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: var(--chat-btn-color);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dotActive {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: var(--chat-btn-active-color);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot:hover {
  background-color: var(--chat-btn-hover-color);
}

@keyframes jumping-dots {
  0%,
  50%,
  100% {
  }
  25% {
    transform: translateY(-5px);
  }
}

@media screen and (max-width: 1700px) {
  .answersContainer {
    bottom: 11vh;
    height: auto;
  }
  .answerPlayer {
    height: 70px;
  }
  .messagesContainer {
    height: 50vh;
  }
}

@media screen and (max-width: 1200px) {
  .answersContainer {
    bottom: 13vh;
    height: auto;
  }
  .answerPlayer {
    height: 70px;
  }
  .messagesContainer {
    height: 50vh;
  }
}
