.container {
  text-align: start;
  padding-bottom: 15vh;
}

.heading {
  text-align: start;
  font-size: 2.2rem;
  font-weight: 700;
  margin: 15px 0;
}

.content {
  text-align: start;
}

.introPicture {
  margin: 2% 0;
}

#background {
  padding: 40px 10px;
  background-color: #e6e6e6;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: none;
}

#background img {
  max-width: 80%;
}

#Anleitung {
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  color: black;
}

#WillkommenText {
  font-size: 16px;
  color: black;
  margin-right: 20px;
  z-index: 2;
}

#Aufbau {
  font-size: 24px;
  font-weight: bold;
  text-decoration: underline;
  color: black;
  z-index: 3;
}

#Taskbar {
  font-size: 24px;
  font-weight: bold;
  color: black;
  z-index: 4;
}

#TaskbarBild {
  z-index: 5;
}

#TaskbarText {
  font-size: 16px;
  color: black;
  margin-right: 20px;
  z-index: 6;
}

#Monitoring {
  font-size: 24px;
  font-weight: bold;
  color: black;
  z-index: 13;
}

#MonitoringBild {
  z-index: 14;
}

#MonitoringText {
  font-size: 16px;
  color: black;
  margin-right: 20px;
  z-index: 15;
}

#Dashboard {
  font-size: 24px;
  font-weight: bold;
  color: black;
  z-index: 4;
}

#TaskAreaBild {
  z-index: 5;
}

#DashboardText {
  font-size: 16px;
  color: black;
  margin-right: 20px;
  z-index: 6;
}

#ErweiterteTaskbar {
  font-size: 24px;
  font-weight: bold;
  color: black;
  z-index: 4;
}

#ErweiterteTaskbarBild {
  z-index: 5;
}

#ErweiterteTaskbarText {
  font-size: 16px;
  color: black;
  margin-right: 20px;
  z-index: 6;
}
