.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

.ressourceWrapper {
  padding: 0 10px 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ressourceChartContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}
.ressourceHeaderContainer {
  text-align: start;
  padding-left: 10px;
}
.ressourceHeader {
  color: #5a5c68;
}

.upgradeHeaderContainer {
  text-align: start;
  padding-left: 10px;
}

.upgradeHeader {
  color: var(--program-border-color);
  margin: 12px 0 0 0;
}

.chartCard {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 0.25rem;
  align-self: stretch;
}
.chartCardHeaderContainer {
  background-color: #f8f9fc;
  text-align: start;
  padding-left: 10px;
}

.chartCardHeader {
  color: var(--program-border-color);
}

.chartContainer {
  padding: 20px;
}

.chartLine {
  position: relative;
  height: 20vw !important;
  flex-basis: 50%;
}
.chartPie {
  position: relative;
  height: 16vw !important;
  flex-basis: 30%;
}

.storageLegendContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 12px;
}

.storageLegendContainer p {
  font-size: 0.8rem;
  display: flex;
}

.storageOccupied::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 4px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: var(--program-border-color);
}
.storageFree::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 4px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #aaa;
}

.serverUpdatesContainer {
  display: flex;
  flex-direction: row;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 0.25rem;
  align-self: stretch;
  gap: 12px;
  flex-wrap: wrap;
}

.upgradeButtonContainer {
  width: 100%;
  display: flex;
  gap: 12px;
  padding: 18px;
}

.upgradeWrapper {
  display: flex;
  flex: 1;
  align-items: center;
  border-radius: 0.25rem;
  background-color: #d03978;
  gap: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.upgradeWrapper:hover {
  transform: scale(1.01);
}

.upgradeText {
  display: flex;
  font-size: 0.7rem;
  padding: 4px 4px;
  gap: 4px;
}

.upgradeText p {
  margin: 0;
  color: white;
}

.upgradeIcon {
  display: flex;
  background-color: #b13066;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  border-radius: 0.25rem;
  width: 10%;
}
.upgradeIcon svg {
  height: 10px;
  width: 10px;
  fill: #d898b3;
}
