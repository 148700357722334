.container{
    margin-bottom: 11vh;
}

.header {
  width: 100%;
  height: 8vh;
  background-color: #323844;
  color: white;
  font-size: 2rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.subheader {
  width: 100%;
  height: 8vh;
  background-color: #627391;
  color: white;
  font-size: 2rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.link {
  text-decoration: none;
  color: white;
}

.table {
  margin-top: 4%;
  width: 100%;
  background-color: #ffffff;
  border-collapse: collapse;
  border-width: 2px;
  color: #000000;
}

.content {
  margin: 2%;
  text-align: start
}

.check {
  display: flex;
  float: left;
  margin-right: 2%;
}

.tableHeader {
    font-size: 1.7rem;
}

.line {
  margin-left: 20px;
  margin-right: 20px;
}
