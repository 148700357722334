/*Buttons of Sites*/
.filterButton {
  width: 90%;
  display: flex;
  justify-content: center;
  margin-bottom: 2%;
  padding: 3%;
  border-radius: 8px;
  font-size: 1.3rem;
  background-color: var(--program-btn-color);
  background-position: center;
  background-size: cover;
  color: var(--game-blue-btn-text-color);
  border: none;
  cursor: pointer;
}

.filterButton:hover {
  width: 90%;
  background-color: var(--program-btn-hover-color);
  color: var(--game-blue-btn-text-color);
}

.filterButton:active {
  width: 90%;
  color: var(--program-btn-active-color);
  color: var(--game-blue-btn-text-color);
}

.filterButton:focus {
  width: 90%;
  color: var(--program-btn-focus-color);
  color: var(--game-blue-btn-text-color);
}

/*Main Container*/
.programContainer {
  flex-grow: 10;
  right: 1.5%;
  left: 1.5%;
  height: 100%;
  flex-direction: column;
}

/*Header Container*/
.programHeader {
  height: 10vh;
  width: 100%;
  text-align: center;
  background-color: var(--program-border-color);
  border-color: var(--program-border-color);
  display: flex;
  align-items: center;
}

/*Heading*/
.programHeading {
  color: white;
  font-family: var(--title-text-font);
  font-size: 3rem;
  margin-right: auto;
  margin-left: auto;
  font-weight: 500;
}

/*Sidebar*/
.programBody {
  display: flex;
  flex-flow: row nowrap;
  height: 90vh;
  width: 99%;
  border: 3px;
  border-color: var(--program-border-color);
}

/*Sidebar Content*/
.programFilter {
  display: flex;
  flex-direction: column;
  min-width: 20%;
  max-width: 20%;
  flex-direction: column;
  align-items: center;
  border-right: 2px grey solid;
  margin-right: 1.5%;
}

/*Content*/
.programMainComponent {
  overflow-y: auto;
  display: block;
  height: calc(100% - 120px);
  padding-bottom: 20px;
  width: 100%;
}
