.heading {
  text-align: start;
  font-size: 2.5rem;
  font-weight: 700;
  margin: 15px 0;
}

.newHeading {
  display: flex;
  flex-direction: column;
}
.newHeading * {
  margin: 2px;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 20px;
}

.headerWrapper #logo img {
  height: 180px;
  width: 180px;
}

.visualRatingContainer {
  margin-top: 12px;
}
.visualRatingContainer p {
  margin: 2px;
}
