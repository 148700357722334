.buttonContainer {
  width: clamp(100px, 16vw, 200px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  gap: 2px;
  background-color: #d03978;
  font-size: 10px;
  border-radius: 8px;
  position: relative;
  cursor: default;
}

.timeTrack {
  position: absolute;
  bottom: -6px;
  height: 4px;
  background-color: #d03978;
  z-index: 1;
}

/* Tooltip text */
.buttonContainer .tooltiptext {
  visibility: hidden;
  justify-content: center;
  width: 200px;
  background-color: #fff;
  color: #000;
  text-align: center;
  padding: 5px 5px;
  border-radius: 10px;
  border: 3px solid rgb(226, 30, 121);

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 1s;
}

/* Tooltip arrow */
.buttonContainer .tooltiptext::after {
  position: absolute;
  top: 100%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.buttonContainer:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
