.startpage {
  text-align: center;
  padding-bottom: 15vh;
  justify-content: center;
  width: 100%;
}

.pages {
  display: flex;
  flex-direction: row;
}

.container {
  position: relative;
  width: 50%;
}

.saas {
  word-spacing: 1%;
  text-transform: uppercase;
  font-size: large;
  letter-spacing: 0.3rem;
  color: #242fce;
  font-weight: 500;
  margin-top: 30%;
}

.heading {
  font-weight: bold;
  font-size: 45px;
  margin-top: 1rem;
}

.subtext {
  font-weight: 500;
  color: gray;
  margin-top: 1rem;
}

.contact {
  background: rgb(21, 11, 173);
  background: linear-gradient(90deg,
      rgb(27, 14, 202) 0%,
      rgb(53, 41, 216) 100%);
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 10px;
  width: 180px;
  color: white;
  font-weight: bold;
  margin-top: 2.5rem;
}

.contact:hover {
  transform: scale(1.05);
  transition: all 0.6s ease-in;
}

.button {
  display: flex;
  justify-content: center;
}

/*Partner*/
.partner {
  width: 100%;
  margin-top: 7rem;
}

.text {
  font-size: 2rem;
  font-weight: bold;
}

.logos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 20rem;
  margin-right: 20rem;
  margin-top: 3rem;
}

/*Rating*/

.logo {
  margin-top: 5rem;
}

.ratingHeader {
  font-size: 2.5rem;
  font-weight: 800;
  text-transform: uppercase;
}

.ratingheading {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 5rem;
}

.reachedpoints {
  font-weight: bolder;
  font-size: 3.5rem;
}

.points {
  color: gray;
  font-weight: bolder;
  font-size: 2.5rem;
}

.rating {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1.5rem;
}

.feedback {
  padding: 2rem;
  width: 15vw;
  margin: 1.5rem;
  font-weight: 600;
  -webkit-box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.24);
}

.name {
  font-weight: bold;
  font-size: large;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.stars {
  color: rgb(240, 205, 8);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.text {
  font-weight: 600;
  font-size: 1.5rem;
}

@media screen and (max-width: 1700px) {
  .startpage {
    width: 90%;
  }

  .container {
    width: 50%;
  }

  .rating {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1.5rem;
  }

  .feedback {
    padding: 2rem;
    width: 15vw;
    margin: 1.5rem;
    font-weight: 600;
    box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.24);
  }

  .text {
    text-align: center;
  }

  .logos {
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    justify-content: center;
  }

  .pages {
    justify-content: center;
  }

  .startpage {
    width: 100%;
    justify-content: center;
    text-align: center;
    padding-bottom: 15vh;
  }
}

@media screen and (max-width: 1200px) {
  .rating {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
  }

  .feedback {
    padding: 2rem;
    width: 30vw;
    margin: 1.5rem;
    font-weight: 600;
    -webkit-box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.57);
    box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.24);
  }

  .text {
    text-align: center;
  }

  .logos {
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    justify-content: center;
  }

  .pages {
    justify-content: center;
  }

  .startpage {
    width: 100%;
    justify-content: center;
    text-align: center;
    padding-bottom: 15vh;
  }

  .blob {
    display: none;
  }

  .container:nth-child(1) {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .feedback {
    padding: 2rem;
    width: 50vw;
    margin: 1.5rem;
    font-weight: 600;
    -webkit-box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.57);
    box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.24);
  }
}