/* Container */
.container {
    position: absolute; /* Stay in place */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100vw; /* Full width */
    height: 100vh; /* Full height */
    overflow: auto; /* Enable scroll if needed */
  }
  
  /* Content of Modal */
  .modalcontent {
    position: fixed;
    background-color: #fefefe;
    padding-left: 1%;
    padding-right: 1%;
    border: 5px solid #000;
    border-radius: 50px;
    height: 60%;
    width: 40%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  /* Header */
  .modalheader {
    margin-top: 5%;
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  /* Content */
  .content {
    padding-bottom: 2%;
    height: 70%;
    overflow-y: auto;
  }

  /* Close icon */
  .close {
    position: absolute;
    top: 3%;
    right: 3%;
    cursor: pointer;
  }
  
  @media screen and (max-width: 1500px) {
    .close {
      position: absolute;
      top: 3%;
      right: 3%;
      cursor: pointer;
    }
  
  }
  