.desktop {
  background-image: url("../../../assets/icons/Background_PictureIT.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: calc(100vh - 120px);
  width: 100vw;
}

.instructionContainer {
  display: flex;
  padding: 10px;
  padding-top: clamp(10px, 10vw, 40px);
}

.instruction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 5%;
}

/* .readMe {
  z-index: 1;
  position: absolute;
  top: 5%;
  left: 5%;
}
.readMeTwo {
  z-index: 1;
  position: absolute;
  top: 5%;
  left: 20%;
}

.subtext {
  z-index: 1;
  position: absolute;
  top: 15%;
  left: 5%;
}
.subtextTwo {
  z-index: 1;
  position: absolute;
  top: 16%;
  left: 20%;
  text-decoration: none;
  color: initial;
} */

/* @media screen and (max-width: 4000px) {
  .subtext {
    z-index: 1;
    position: absolute;
    top: 10%;
  }
  .readMe {
    z-index: 1;
    position: absolute;
    top: 5%;
  }
}

@media screen and (max-width: 2000px) {
  .subtext {
    z-index: 1;
    position: absolute;
    top: 15%;
  }
}

@media screen and (max-width: 1800px) {
  .subtext {
    z-index: 1;
    position: absolute;
    top: 18%;
  }
}

@media screen and (max-width: 1500px) {
  .subtext {
    z-index: 1;
    position: absolute;
    top: 17.5%;
  }
} */
