.container {
  position: absolute; /* Stay in place */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
}

/* Content of modal */
.modalcontent {
  position: fixed;
  background-color: #fefefe;
  padding-left: 20px;
  padding-right: 20px;
  border: 5px solid #000;
  border-radius: 50px;
  height: 70%;
  width: 60%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* Header */
.modalheader {
  margin-top: 5%;
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
}

/* Container of Buttons */
.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding-left: 2%;
  padding-right: 2%;
  overflow-y: scroll;
  height: 70%;
}

/* Close icon */
.close {
  position: absolute;
  top: 3%;
  right: 3%;
  cursor: pointer;
}

/* Button of achievement */
.erfolg {
  width: 90%;
  border: var(--primary-bg-color) solid 4px;
  margin-bottom: 2%;
  border-radius: 30px;
  padding-top: 2%;
  padding-bottom: 2%;
}
