.modal {
    position: absolute; /* Stay in place */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
  }
  
  .modalcontent {
    position: fixed;
    background-color: #fefefe;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    border: 5px solid #000;
    border-radius: 50px;
    height: auto;
    width: 70%;
    padding-top: 1%;
    padding-bottom: 1%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .modalheader {
    margin-top: 3%;
    font-size: 3rem;
    font-weight: 600;
  }

  .close {
    position: absolute;
    top: 3%;
    right: 3%;
    cursor: pointer;
  }